"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storage = storage;
var defaultStorageName = "configurator-studio-arkitekter";
var memoStorage = {};

function getItem(storageName) {
  return memoStorage[storageName];
}

function setItem(storageName, item) {
  return memoStorage[storageName] = item;
}

function storage() {
  var newData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var storageName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultStorageName;

  if (typeof window !== "undefined") {
    try {
      var currentData = JSON.parse(getItem(storageName) || JSON.stringify({}));
      var tempCurrentDataString = JSON.stringify(currentData);
      Object.keys(newData).forEach(function (key) {
        currentData[key] = newData[key];
      });

      if (tempCurrentDataString !== JSON.stringify(currentData)) {
        setItem(storageName, JSON.stringify(currentData));
      }

      return currentData;
    } catch (e) {
      return newData;
    }
  }

  return newData;
}